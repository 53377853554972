<template>
  <div class="chats">
    <!-- {{ $store.getters["user/getUserDetails"] }} -->
    <!-- :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.id }" -->
    <!-- {{ formattedChatData.formattedChatLog }} -->
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{
        'chat-left':
          msgGrp.senderId !== $store.getters['user/getUserDetails'].user_id,
      }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="
            msgGrp.senderId !== $store.getters['user/getUserDetails'].user_id
              ? FILESURL + formattedChatData.contact.avatar
              : profileUserAvatar
          "
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, index) in msgGrp.messages"
          :key="'msg_key_' + index"
          class="chat-content"
          v-b-tooltip.hover
        >
          <!-- :title="toLocalTime(msgData.time)" -->

          <p :id="'msg_id_' + index">
            {{ msgData.msg }}
            <sub>{{ formatDateTime(msgData.time) }}</sub>
            <!-- <sub>{{ msgData.time }}</sub> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { BAvatar, BTooltip } from "bootstrap-vue";
import { FILESURL } from "@/config";
import helpers from '@/libs/helpers';

export default {
  components: {
    BAvatar,
    BTooltip,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      };

      let chatLog = [];
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat;
      }

      const formattedChatLog = [];
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined;
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
          });
        } else {
          chatMessageSenderId = msg.senderId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.message,
                time: msg.time,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      };
    });

    return {
      FILESURL,
      formattedChatData,
    };
  },
  methods: {
    formatDateTime: helpers.getDateTimeFormatted
  },
};
</script>

<style>
</style>
