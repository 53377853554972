import CommonServices from '@/apiServices/CommonServices';
import StudentServices from '@/apiServices/StudentServices'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        CommonServices.getChatConnections()
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        // axios
        //   .get('/apps/chat/users/profile-user')
        //   .then(response => resolve(response))
        //   .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        CommonServices.getMessage({ receiver_id: userId })
          .then(response => resolve(response))
          .catch(err => reject(err))
      })
    },
    sendMessage(ctx, { contactId, message }) {
      return new Promise((resolve, reject) => {
        CommonServices.sendMessage({ receiver_id: contactId, message })
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },
  },
}
